// eslint-disable-next-line import/prefer-default-export
export const legalSubverticals = {
  title: "SpotOn terms & policies",
  noMore: true,

  subverticalInfo: [
    {
      iconImg: "terms.png",
      subverticalName: "Terms of Services.",
      links: [
        {
          linkTitle: "Merchants",
          linkTarget: "/legal/merchant-terms",
        },
        {
          linkTitle: "Users",
          linkTarget: "/legal/user-terms",
        },
        {
          linkTitle: "Point-of-Sale",
          linkTarget: "/legal/point-of-sale-terms",
        },
        {
          linkTitle: "SpotOn Website",
          linkTarget: "/legal/website-terms",
        },
        //
        //   linkTitle: 'Payments',
        //   linkTarget:'/payments-terms.pdf',
        // },
        {
          linkTitle: "SpotOn Reserve",
          linkTarget: "/legal/reserve-terms",
        },
        {
          linkTitle: "SpotOn Teamwork",
          linkTarget: "/legal/teamwork-terms",
        },
        {
          linkTitle: "Gift Cards",
          linkTarget: "/legal/giftcards",
        },
      ],
      subverticalContent:
        "Read our Terms of Service for SpotOn merchants and consumers.",
    },
    {
      iconImg: "policies.png",
      subverticalName: "Policies",
      subverticalContent:
        "Explore our privacy statement for details on how we collect and use data.",
      links: [
        {
          linkTitle: "Merchant Privacy",
          linkTarget: "/legal/merchant-privacy",
        },
        {
          linkTitle: "Accessibility",
          linkTarget: "/legal/accessibility-statement",
        },
        {
          linkTitle: "Consumer Privacy",
          linkTarget: "/legal/consumer-privacy",
        },
        {
          linkTitle: "SMS Terms",
          linkTarget: "/legal/sms-terms-and-conditions",
        },
      ],
    },
  ],
};

export const legalDefinitionsConsumer = [
  {
    linkName:
      "“Consumer” is personal data from individuals collected directly by SpotOn or from your direct interaction with Merchants",
  },
  {
    linkName:
      "“Data” includes both Non-Personal Information and Personally Identifiable Information.",
  },
  { linkName: "“SpotOn”, “we,” “us” and “our” means SpotOn." },
  {
    linkName:
      "“Merchant” is a business that offers SpotOn products and services",
  },
  {
    linkName:
      "“Non-Personal Information” is any information that is not Personally Identifiable Information (defined below). Non-Personal Information includes, without limitation, Personally Identifiable Information that has been anonymized.",
  },
  {
    linkName:
      "“Personal Information,” “Personally Identifiable Information” or “PII” shall have the same meaning as determined by applicable law but includes only information we receive from your use of the Site. To the extent applicable law does not define Personal Information, it is non-public information we receive from your use of the Site that can be used, alone or in combination with other information in our possession, to identify a particular person/individual. It may include information such as name, address, telephone number and other personal information you provide us.",
  },
  {
    linkName:
      "“Services” products or services that are or may be provided through the Site or that we may otherwise offer online or offline",
  },
  {
    linkName:
      "“Site” refers to the following websites, as applicable, including any related blogs, domains, mobile sites, online services and applications: SpotOn.com, SpotOn and any other applications from SpotOn Transact, LLC.",
  },
  {
    linkName:
      "“You” and “your” mean the individual or entity visiting or using the Site.",
  },
];
export const informationCollectedConsumer = [
  { linkName: "Cookies and Tracking Technologies" },
  {
    linkName:
      "How SpotOn uses information it obtains through a SpotOn Website, SpotOn Mobile or the Services",
  },
  {
    linkName:
      "How SpotOn may disclose information it obtains through a SpotOn Website, SpotOn Mobile or the Services",
  },
  {
    linkName: "What choices you have about how SpotOn treats your information",
  },
  { linkName: "How SpotOn protects your privacy" },
  { linkName: "California and US State Law Notice" },
  {
    linkName:
      "What SpotOn does about children visiting a SpotOn Website or Spoton Mobile",
  },
  { linkName: "How comments you may make via SpotOn are handled" },
  { linkName: "How you can contact SpotOn" },
  { linkName: "Information SpotOn Collects from you" },
];

export const legalListCPP = [
  {
    linkName: "What information SpotOn collects from you",
    linkTo: "/legal/consumer-privacy#info",
  },
  {
    linkName:
      "How SpotOn uses and may disclose information it obtains through a SpotOn \n" +
      "Website, SpotOn Mobile or the Services",
    linkTo: "/legal/consumer-privacy#disclosure",
  },
  {
    linkName: "What choices you have about how SpotOn treats your information",
    linkTo: "/legal/consumer-privacy#choices",
  },
  {
    linkName: "How SpotOn protects your privacy ",
    linkTo: "/legal/consumer-privacy#privacy",
  },
  {
    linkName:
      "What SpotOn does about children visiting a SpotOn Website or Spoton Mobile",
    linkTo: "/legal/consumer-privacy#children",
  },
  {
    linkName: "How comments you may make via SpotOn are handled;",
    linkTo: "/legal/consumer-privacy#comments",
  },
  {
    linkName: "How you can contact SpotOn",
    linkTo: "/legal/consumer-privacy#contact",
  },
];

export const legalListMPP = [
  {
    linkName: "INFORMATION WE COLLECT ABOUT YOU",
    linkTo: "/legal/merchant-privacy#info-you-provide",
  },
  {
    linkName: "INFORMATION WE MAY COLLECT ABOUT YOUR CUSTOMERS",
    linkTo: "/legal/merchant-privacy#info-your-customers",
  },
  {
    linkName: "SOURCES OF INFORMATION WE COLLECT ABOUT YOU",
    linkTo: "/legal/merchant-privacy#info-sources",
  },
  {
    linkName: "HOW WE USE YOUR INFORMATION ",
    linkTo: "/legal/merchant-privacy#how-we-use-it",
  },
  {
    linkName: "WHEN AND WITH WHOM WE SHARE YOUR INFORMATION",
    linkTo: "/legal/merchant-privacy#sharing",
  },
  {
    linkName: "COOKIES AND OTHER SIMILAR TECHNOLOGIES",
    linkTo: "/legal/merchant-privacy#cookies",
  },
  {
    linkName: "THIRD-PARTY ANALYTICS SERVICES",
    linkTo: "/legal/merchant-privacy#third-parties",
  },
  {
    linkName: "ONLINE TRACKING AND INTEREST-BASED ADVERTISING",
    linkTo: "/legal/merchant-privacy#online-tracking",
  },
  {
    linkName: "HOW LONG WE KEEP YOUR INFORMATION",
    linkTo: "/legal/merchant-privacy#how-long",
  },
  {
    linkName: "YOUR CHOICES",
    linkTo: "/legal/merchant-privacy#choices",
  },
  {
    linkName: "ONLINE BEHAVIORAL OR INTEREST-BASED ADVERTISING",
    linkTo: "/legal/merchant-privacy#online-advertising",
  },
  {
    linkName: "RIGHTS OF CALIFORNIA RESIDENTS",
    linkTo: "/legal/merchant-privacy#ca-residents",
  },
  {
    linkName: "CHILDREN’S PERSONAL INFORMATION",
    linkTo: "/legal/merchant-privacy#children",
  },
  {
    linkName: "SECURITY",
    linkTo: "/legal/merchant-privacy#security",
  },
  {
    linkName: "STORAGE AND PROCESSING",
    linkTo: "/legal/merchant-privacy#storage",
  },
  {
    linkName: "CHANGES TO THIS PRIVACY POLICY",
    linkTo: "/legal/merchant-privacy#changes",
  },
  {
    linkName: "CONTACT",
    linkTo: "/legal/merchant-privacy#contact",
  },
];

export const columns = [
  {
    title: "Category of Personal Information Collected",
    key: "cat-p",
    dataIndex: "cat-p",
  },
  {
    title: "Categories of Sources from which the Information was Collected",
    key: "cat-sor",
    dataIndex: "cat-sor",
  },
  {
    title:
      "Business or Commercial Purpose(s) for which Information is Collected",
    key: "biz-p",
    dataIndex: "biz-p",
  },
  {
    title:
      "Categories of Third Parties to whom this type of Personal Information is Disclosed for a Business Purpose",
    key: "third-p",
    dataIndex: "third-p",
  },
  {
    title:
      "Categories of Third Parties to Whom this Type of Personal Information is Sold or Shared for Cross Contextual Advertising",
    key: "cat-thr",
    dataIndex: "cat-thr",
  },
];
export const dataset = [
  {
    "cat-p": "Identifiers",
    "cat-sor":
      "We may collect this type of information from:\n" +
      "You,\n" +
      "Cookies and Tracking Technologies,\n" +
      "Third party social media companies,\n" +
      "Third party business partners, such as data analytics providers, advertising networks, or joint marketing partners.\n",
    "biz-p":
      "To improve customer service \n" +
      "To personalize user experience\n" +
      "To improve the Site \n" +
      "To provide products or services and to process transactions\n" +
      "To send periodic mail, emails and surveys\n" +
      "To develop new products or services\n" +
      "For fulfillment of Obligations\n" +
      "To comply with contractual obligations, relevant industry standards and our policies\n" +
      "For marketing\n" +
      "For Compliance with applicable law\n" +
      "For Security\n" +
      "Change in the Site Ownership\n" +
      "For Payment Processing\n",
    "third-p":
      "We may disclose this type of information to:\n" +
      "Service Providers,\n" +
      "Third parties we partner with for contests, sweepstakes, or promotions,\n" +
      "Third party advertising and analytics companies,\n" +
      "Third parties who may acquire your information as a result of a merger, acquisition or otherwise ownership transition\n" +
      "Third parties or affiliated companies when you agree to or direct that we share your information with them\n" +
      "Other third parties (including government agencies) as required by law or in connection with court proceedings (such as pursuant to subpoenas or court orders)\n" +
      "\n",
    "cat-thr":
      "Third party advertising, analytics, and other similar marketing business partners may have access to this data, and this may be considered a “sale” or “share” under the CCPA and CPRA and other laws under certain circumstances.\n" +
      "\n",
  },
  {
    "cat-p":
      "Any categories of personal information described in Section 1798.140",
    "cat-sor": "You",
    "biz-p":
      "Allow you to participate in features we offer or to provide related customer services\n" +
      "recognize you across devices\n" +
      "tailor content recommendations and offers we display to you\n" +
      "provide you with information, products, or services that you have requested or that we think may interest you\n" +
      "investigate and prevent fraudulent transactions and other illegal activities or activities that violate our policies\n" +
      "process your registration, including verifying your information is active and valid\n" +
      "contact you with regard to your use of our product\n",
    "third-p":
      "Service providers who assist us in carrying out these business functions.",
    "cat-thr": "N/A",
  },
  {
    "cat-p":
      "Commercial information such as records of personal or commercial property for restaurants owners",
    "cat-sor":
      "We may collect this type of information from:\n" +
      "\n" +
      "You,\n" +
      "Third party social media companies,\n" +
      "Third party business partners such as data analytics providers, advertising networks, or joint marketing partners\n" +
      "\n",
    "biz-p":
      "To support the underwriting process for processing account and/or loans\n",
    "third-p": "Service providers to support the underwriting process\n" + "\n",
    "cat-thr": "N/A",
  },
  {
    "cat-p": "Internet or other electronic network activity information\n",
    "cat-sor":
      "We may collect this type of information from:\n" +
      "Cookies and Tracking Technologies",
    "biz-p":
      "Allow you to participate in features we offer or to provide related customer services\n" +
      "Recognize you across devices\n" +
      "Tailor content recommendations and offers we display to you\n" +
      "Provide you with information, products, or services that you have requested or that we think may interest you\n" +
      "Investigate and prevent fraudulent transactions and other illegal activities or activities that violate our policies\n",
    "third-p":
      "Service providers who assist us in carrying out these business functions",
    "cat-thr":
      "Third party advertising, analytics, and other similar marketing business partners may have access to this data, and this may be considered a “sale” or “share” under the CCPA and CPRA and other laws under certain circumstances.\n",
  },
  {
    "cat-p": "Geolocation data\n",
    "cat-sor":
      "We may collect this type of information from:\n" +
      "Cookies and Tracking Technologies\n",
    "biz-p": "For delivery services at restaurants to facilitate the service\n",
    "third-p":
      "Service providers who assist us in carrying out these business functions\n",
    "cat-thr": "N/A",
  },
  {
    "cat-p":
      "Inferences drawn from any of the information identified above to create a profile about a You\n",
    "cat-sor":
      "We may collect this type of information from:\n" +
      "You,\n" +
      "Cookies and Tracking Technologies,\n" +
      "Third party social media companies,\n" +
      "Third party business partners, such as data analytics providers, advertising networks, or joint marketing partners,\n" +
      "Third party data resellers.\n",
    "biz-p": "",
    "third-p":
      "Service providers who assist us in carrying out these business functions\n",
    "cat-thr": "N/A",
  },
  {
    "cat-p":
      "A consumer’s social security, driver’s license, state identification card, or passport number.",
    "cat-sor": "You",
    "biz-p":
      " For identity verification, fraud prevention and to provide to our bank sponsors, acquirers for loans/payment processing\n",
    "third-p": "Service providers\n",
    "cat-thr": "N/A",
  },
  {
    "cat-p":
      "A consumer’s account log-in, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account.",
    "cat-sor": "You",
    "biz-p":
      "To process transactions we collect card data. Password and credentials may be captured if we have a loyalty or wallet account",
    "third-p": "Service Providers\n",
    "cat-thr": "N/A",
  },
  {
    "cat-p": "A consumer’s precise geolocation.",
    "cat-sor": "You",
    "biz-p":
      "We collect precise data/address for delivery services at restaurants but only to facilitate the service\n",
    "third-p": "Service providers \n",
    "cat-thr": "N/A",
  },
  {
    "cat-p":
      "The contents of a consumer’s mail, email, and text messages unless the business is the intended recipient of the communication",
    "cat-sor": "N/A",
    "biz-p": "N/A",
    "third-p": "N/A",
    "cat-thr": "N/A",
  },
];
