import React from 'react';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import Subverticals from '../components/SubVerticals/Subverticals';
import { legalSubverticals } from '../data/legal-data';

const Legal = () => {
  return (
    <Layout className="about-us">
      <SEO title="SpotOn - Legal" />
      <Subverticals sectionData={legalSubverticals} />
    </Layout>
  );
};

export default Legal;
